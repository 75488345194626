import { makeAutoObservable } from "mobx";



import Parse from "parse";
import { Chat, Message } from "../parse";

export class ChatService {
  

   __init() {this.chats = []}
   __init2() {this.messages = []}

  constructor(kommuter) {;ChatService.prototype.__init.call(this);ChatService.prototype.__init2.call(this);
    this.kommuter = kommuter;

    makeAutoObservable(this);

    if (Parse.User.current()) {
    }
  }

   async onUser(user) {
    const online = this.kommuter.network.connected;

    // Load chats

    const chatQuery = new Parse.Query(Chat)
      // TODO: Not working
      .descending("lastMessageAt")
      .include("offer")
      .include("requestUser")
      .include("offerUser")
      .include("requestUserProfile")
      .include("offerUserProfile")
      .include("start")
      .include("end");

    if (!online) {
      chatQuery.fromLocalDatastore();
    }

    await this.updateChats(chatQuery);

    if (online) {
      const chatSubscription = await chatQuery.subscribe();

      chatSubscription.on("open", () => {
        console.info("chatSubscription opened");
      });

      chatSubscription.on("create", (object) => {
        console.info("chat created");
        this.updateChats(chatQuery);
      });

      chatSubscription.on("update", (object) => {
        console.info("chat updated");
        this.updateChats(chatQuery);
      });

      chatSubscription.on("enter", (object) => {
        console.info("chat entered");
        this.updateChats(chatQuery);
      });

      chatSubscription.on("leave", (object) => {
        console.info("chat left");
        this.updateChats(chatQuery);
      });

      chatSubscription.on("delete", (object) => {
        console.info("chat deleted");
        this.updateChats(chatQuery);
      });

      chatSubscription.on("close", () => {
        console.info("chatSubscription closed");
      });
    }

    // Load messages

    const messageQuery = new Parse.Query(Message).ascending("createdAt");

    if (!online) {
      messageQuery.fromLocalDatastore();
    }

    await this.updateMessages(messageQuery);

    if (online) {
      const messagesSubscription = await messageQuery.subscribe();

      messagesSubscription.on("open", () => {
        console.info("messagesSubscription opened");
      });

      messagesSubscription.on("create", (object) => {
        console.info("messages created");
        this.updateMessages(messageQuery);
      });

      messagesSubscription.on("update", (object) => {
        console.info("messages updated");
        this.updateMessages(messageQuery);
      });

      messagesSubscription.on("enter", (object) => {
        console.info("messages entered");
        this.updateMessages(messageQuery);
      });

      messagesSubscription.on("leave", (object) => {
        console.info("messages left");
        this.updateMessages(messageQuery);
      });

      messagesSubscription.on("delete", (object) => {
        console.info("messages deleted");
        this.updateMessages(messageQuery);
      });

      messagesSubscription.on("close", () => {
        console.info("messagesSubscription closed");
      });
    }
  }

   async updateChats(query) {
    const chats = await query.find();

    if (this.kommuter.network.connected) {
      await Parse.Object.pinAll(chats);
    }

    this.chats = chats;
  }

   async updateMessages(messageQuery) {
    const messages = await messageQuery.find();

    if (this.kommuter.network.connected) {
      await Parse.Object.pinAll(messages);
    }

    this.messages = messages;
  }
}
