const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\AdminUsersRoute.tsx";import Parse from "parse";
import React from "react";
import { useParams } from "react-router-dom";
import { AdminUsersList } from "..";

const unverifiedQuery = new Parse.Query(Parse.User)
  .equalTo("tenantVerified", false)
  .notEqualTo("tenantBanned", true)
  .notEqualTo("tenantAdmin", true)
  .include("rsProfile");

const verifiedQuery = new Parse.Query(Parse.User)
  .equalTo("tenantVerified", true)
  .notEqualTo("tenantBanned", true)
  .notEqualTo("tenantAdmin", true)
  .include("rsProfile");

const bannedQuery = new Parse.Query(Parse.User)
  .equalTo("tenantBanned", true)
  .notEqualTo("tenantAdmin", true)
  .include("rsProfile");

const adminsQuery = new Parse.Query(Parse.User)
  .equalTo("tenantAdmin", true)
  .include("rsProfile");

export function AdminUsersRoute() {
  console.log("test 2");
  let { tab } = useParams();

  tab ??= "unverified";

  return (
    React.createElement(React.Fragment, null
      , React.createElement(AdminUsersList, {
        query: unverifiedQuery,
        context: tab,
        active: tab === "unverified", __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
      )
      , React.createElement(AdminUsersList, {
        query: verifiedQuery,
        context: tab,
        active: tab === "verified", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
      )
      , React.createElement(AdminUsersList, {
        query: bannedQuery,
        context: tab,
        active: tab === "banned", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
      )
      , React.createElement(AdminUsersList, {
        query: adminsQuery,
        context: tab,
        active: tab === "admins", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
      )
    )
  );
}
