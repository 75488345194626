 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { usePromise } from "@opendash/core";
import moment from "moment";

import {
  fetchPublicTransport,
  getUserProfile,

  useCurrentPosition,
} from "..";

export function usePublicTransportNearby() {
  const [lat, long] = useCurrentPosition();

  return usePromise(() => {
    const distance = _optionalChain([getUserProfile, 'call', _ => _(), 'optionalAccess', _2 => _2.searchBuffer]) || 1000;

    return fetchPublicTransportNearby(lat, long, distance);
  }, [lat, long]);
}

async function fetchPublicTransportNearby(
  lat,
  long,
  distance = 500
) {
  const nearbyStops = await fetchPublicTransport("/stops/nearby", {
    latitude: lat.toString(),
    longitude: long.toString(),
    distance: distance.toString(),
    language: "de",
  });

  const nearbyDepartures = await Promise.all(
    nearbyStops.map((stop) =>
      fetchPublicTransport(`/stops/${stop.id}/departures`, {
        when: new Date().toJSON(),
        language: "de",
      })
    )
  );

  const result = nearbyDepartures
    .flat()
    .sort((a, b) => a.when - b.when)
    .filter((v, i) => i < 3)
    .map((departure) => {
      const when = moment(departure.when).format("HH:mm");

      return {
        title: departure.line.name + " um " + when,
        description: [departure.stop.name].join(" → "),
        stops: [],
      };
    });

  return result;
}
