const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\AuthSignupForm.tsx";import {

  FormGenerator,
  useTranslation,
} from "@opendash/core";
import React from "react";
import { useCloudCodeTenantOptions } from "..";

export const AuthSignupForm = ({ onSubmit }) => {
  const t = useTranslation();

  const { result, loading } = useCloudCodeTenantOptions();

  if (loading || result.length === 0) {
    return null;
  }

  return (
    React.createElement(FormGenerator, {
      onSubmit: (data) => {
        onSubmit(data);
      },
      submit: {
        children: t("opendash:auth.fields.signup_submit"),
        type: "primary",
        htmlType: "submit",
        style: {
          width: "100%",
        },
      },
      settings: {
        hideLabels: true,
      },
      elements: [
        {
          key: "tenant",
          type: "select",
          label: t("rs:auth.tenant_field_label"),
          settings: {
            placeholder: t("rs:auth.tenant_field_placeholder"),
            prefixIcon: "fa:building",
            options: result || [],
          },
          rules: [
            {
              required: true,
              message: t("rs:auth.tenant_field_required"),
            },
          ],
        },
        {
          key: "name",
          type: "input",
          label: t("opendash:auth.fields.name"),
          settings: {
            placeholder: t("opendash:auth.fields.name"),
            prefixIcon: "fa:user",
          },
          rules: [
            {
              required: true,
              message: t("opendash:auth.fields.name_required"),
            },
          ],
        },
        {
          key: "email",
          type: "input",
          label: t("opendash:auth.fields.email"),
          settings: {
            placeholder: t("opendash:auth.fields.email"),
            prefixIcon: "fa:envelope",
          },
          rules: [
            {
              required: true,
              message: t("opendash:auth.fields.email_required"),
            },
          ],
        },
        {
          key: "password",
          type: "input.password",
          label: t("opendash:auth.fields.password"),
          settings: {
            placeholder: t("opendash:auth.fields.password"),
            prefixIcon: "fa:lock",
          },
          rules: [
            {
              required: true,
              message: t("opendash:auth.fields.password_required"),
            },
          ],
        },
      ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
    )
  );
};
