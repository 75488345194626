const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\RideSearchResultsDefault.tsx";import { useTranslation } from "@opendash/core";
import React from "react";
import {
  getUserProfile,
  ListContainer,
  MetaIcon,
  RideOfferResultList,
  TaxiInfoList,
  TitleContainer,
  usePublicTransportRoute,
  useRideSearch,
  useRideSearchStart,
} from "..";
import { PublicTransportList } from "./PublicTransportList";

export function RideSearchResultsDefault({}) {
  const t = useTranslation();

  const [start] = useRideSearchStart();
  const [pt] = usePublicTransportRoute();
  const offers = useRideSearch();

  return (
    React.createElement(React.Fragment, null
      , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
        , React.createElement(MetaIcon, { icon: "auto3", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}} )
        , t("rs:search.ride_offers")
      )
      , React.createElement(ListContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
        , React.createElement(RideOfferResultList, { data: offers, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} )
      )

      , !getUserProfile().publicTransportDisabled && (
        React.createElement(React.Fragment, null
          , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
            , React.createElement(MetaIcon, { icon: "haltestelle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}} )
            , t("rs:search.public_transport_offers")
          )
          , React.createElement(ListContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
            , React.createElement(PublicTransportList, { data: pt, __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}} )
          )
        )
      )

      , !getUserProfile().otherTransportsDisabled && (
        React.createElement(React.Fragment, null
          , React.createElement(TitleContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
            , React.createElement(MetaIcon, { icon: "taxi", __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}} )
            , t("rs:search.cap_offers")
          )
          , React.createElement(ListContainer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
            , React.createElement(TaxiInfoList, { location: start, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}} )
          )
        )
      )
    )
  );
}
