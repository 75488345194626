const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\TaxiInfoList.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation } from "@opendash/core";
import { Button, List, Modal } from "antd";
import * as Parse from "parse";
import { useParseQuery } from "parse-hooks";
import React from "react";
import {
  ListEmpty,
  MetaIcon,
  TaxiInfo,
  useCurrentPosition,

} from "..";

export function TaxiInfoList({ location }) {
  const t = useTranslation();

  const [latitudePosition, longitudePosition] = useCurrentPosition();

  const latitude = _optionalChain([location, 'optionalAccess', _ => _.get, 'call', _2 => _2("location"), 'optionalAccess', _3 => _3.latitude]) || latitudePosition;
  const longitude = _optionalChain([location, 'optionalAccess', _4 => _4.get, 'call', _5 => _5("location"), 'optionalAccess', _6 => _6.longitude]) || longitudePosition;

  const query = React.useMemo(
    () =>
      new Parse.Query(TaxiInfo)
        .ascending("name")
        .polygonContains(
          "area",
          new Parse.GeoPoint({
            latitude,
            longitude,
          })
        )
        .limit(3),
    [latitude, longitude]
  );

  const { result, loading } = useParseQuery(query);

  if (result.length === 0) {
    return React.createElement(ListEmpty, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}, t("rs:search.results.cap_offers_empty"));
  }

  return (
    React.createElement(List, {
      loading: loading,
      dataSource: result,
      renderItem: (taxi) => (
        React.createElement(List.Item, {
          extra: 
            taxi.get("phone") ? (
              React.createElement(Button, {
                icon: React.createElement(MetaIcon, { icon: "anrufen_waehlen", button: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}} ),
                children: t("rs:search.results.cap_offers_call_action"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
              )
            ) : taxi.get("website") ? (
              React.createElement(Button, {
                icon: React.createElement(MetaIcon, { icon: "googeln", button: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}} ),
                children: t("rs:search.results.cap_offers_web_action"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
              )
            ) : undefined
          ,
          onClick: () => {
            if (taxi.get("phone")) {
              window.open("tel:" + taxi.get("phone"));
            } else if (taxi.get("website")) {
              Modal.confirm({
                title: t("rs:app.open_external_confirm_title"),
                content: t("rs:app.open_external_confirm_description"),
                okText: t("rs:app.open_external_confirm_ok"),
                onOk() {
                  window.open(taxi.get("website"));
                },
              });
            }
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
        
          , React.createElement(List.Item.Meta, {
            title: taxi.get("name"),
            description: 
              taxi.get("phone")
                ? taxi.get("phone")
                : taxi.get("website")
                ? new URL(taxi.get("website")).host
                : null
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
          )
        )
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
    )
  );
}
