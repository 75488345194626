const _jsxFileName = "C:\\Users\\sz\\Code\\openinc-projects\\ride-sharing-app\\app\\js\\ride-sharing-app\\components\\MessengerChatInputDefault.tsx";import { useTranslation } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Button, Input, message as m, } from "antd";
import React from "react";
import styled from "styled-components";
import { Message } from "..";

const Container = styled.div``;

const inputSize = "large";
const inputStyle = {
  // right: 50,
  width: "calc(100% - 50px)",
};
const buttonStyle = {
  position: "absolute",
  bottom: 10,
  right: 10,
};

export function MessengerChatInputDefault({ chat }) {
  const t = useTranslation();
  const [messageDraft, setMessageDraft] = React.useState("");

  const submit = (e) => {
    e.preventDefault();

    const msg = new Message({ chat, message: messageDraft.trim() });

    setMessageDraft("");

    m.config({
      top: 50,
    });
    const hideLoading = m.loading(t("rs:messenger.message.send_loading"), 0);

    msg.save().then(
      () => {
        hideLoading();
      },
      (error) => {
        hideLoading();
        m.error(t("rs:messenger.message.send_error"));
        console.error(error);
      }
    );
  };

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Input.TextArea, {
        autoFocus: true,
        size: inputSize,
        style: inputStyle,
        rows: 1,
        autoSize: true,
        value: messageDraft,
        onChange: (e) => setMessageDraft(e.target.value),
        placeholder: t("rs:messenger.input.placeholder"),
        onPressEnter: submit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
      )

      , React.createElement(Button, {
        type: "primary",
        shape: "circle",
        size: inputSize,
        style: buttonStyle,
        icon: React.createElement(Icon, { icon: "fa:arrow-right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}} ),
        onClick: submit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
      )
    )
  );
}
