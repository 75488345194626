import { makeAutoObservable } from "mobx";



export class RouterService {
  

  constructor(kommuter) {
    this.kommuter = kommuter;

    makeAutoObservable(this);
  }

   navigate(url, replace = false) {
    const state = null;

    if (replace) {
      window.history.replaceState(state, "", url);
    } else {
      window.history.pushState(state, "", url);
    }

    var popStateEvent = new PopStateEvent("popstate", { state });

    window.dispatchEvent(popStateEvent);
  }
}
